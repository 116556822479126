import { string } from 'prop-types';
import React from 'react';
import BrandingIconCard from '../../../../components/BrandingIconCard/BrandingIconCard';
import { convertToTitleCase } from '../../../../util/data';
import css from './PopularDealsCard.module.css';
import { NamedLink, ResponsiveImage } from '../../../../components';

const PopularDealsCard = ({ listings, updatedDealCategories }) => {
  const PERCENTAGE_DISCOUNT_TYPE = 'percentage-discount';
  return (
    <div className={css.popularDealsCardWrapper}>
      <div className={css.cardGrid}>
        {listings.map((item, i) => {
          const subcategory =
            updatedDealCategories
              ?.find(cat =>
                cat.subcategories.some(
                  sub => sub.id === item?.attributes?.publicData?.categoryLevel2
                )
              )
              ?.subcategories.find(
                sub => sub.id === item?.attributes?.publicData?.categoryLevel2
              ) || {};
          const subcategoryColor = subcategory.color || '#000';
          const isPercentageDiscount =
            item?.attributes?.publicData?.categoryLevel2 === PERCENTAGE_DISCOUNT_TYPE;

          const percentage_discount = item?.attributes?.publicData?.percentage_discount
            ? item?.attributes?.publicData?.percentage_discount
            : item?.attributes?.publicData?.discount;

          const discounted_price = item?.attributes?.publicData?.discounted_price;
          const details = item?.attributes;

          return (
            <NamedLink
              name="EditListingPage"
              params={{ slug: details?.title, id: item?.id?.uuid, type: 'edit', tab: 'details' }}
            >
              <div key={i} className={css.card}>
                <div className={css.cardImage}>
                  {isPercentageDiscount ? (
                    <span
                      style={{ backgroundColor: subcategoryColor }}
                      className={css.discountAmunt}
                    >
                      -{percentage_discount}%
                    </span>
                  ) : (
                    <span
                      style={{ backgroundColor: subcategoryColor }}
                      className={css.discountAmunt}
                    >
                      -
                      {discounted_price
                        ? (item?.attributes?.price?.amount / 100 - discounted_price).toFixed(2)
                        : '0'}
                    </span>
                  )}
                  <ResponsiveImage
                    alt="card image"
                    image={item.images[0].attributes.variants['listing-card'].url}
                    className={css.cardImage}
                    variants={"custom-image"}
                  />
                  <span className={css.favIcon}>
                    <BrandingIconCard type="dislike" />
                  </span>
                </div>
                <div className={css.cardBody}>
                  <div className={css.bodyLeft}>
                    <h4>{item.attributes.title}</h4>
                    <div className={css.timeOffer}>
                      <span style={{ background: subcategoryColor }}>
                        {convertToTitleCase(
                          item?.attributes?.publicData?.categoryLevel2
                            ? item?.attributes?.publicData?.categoryLevel2
                            : item?.attributes?.publicData?.categoryLevel1
                        )}
                      </span>
                    </div>
                    <div className={css.servicesPrice}>
                      <span className={css.actualPrice}>
                        {item?.attributes?.price?.currency}
                        {item?.attributes?.price?.amount / 100}
                      </span>
                      <span className={css.discountPrice}>SAR{discounted_price}</span>
                    </div>
                  </div>
                </div>
              </div>
            </NamedLink>
          );
        })}
      </div>
    </div>
  );
};

PopularDealsCard.defaultProps = {
  className: null,
  rootClassName: null,
};

PopularDealsCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default PopularDealsCard;
