import React from 'react';
import Page from '../../components/Page/Page';
import LayoutSingleColumn from '../../components/LayoutComposer/LayoutSingleColumn/LayoutSingleColumn';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './DashboardPage.module.css';
import { bool } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { BrandingIconCard, IconSpinner, PaginationLinks } from '../../components';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { InboxItem } from '../InboxPage/InboxPage';
import { getStateData } from '../InboxPage/InboxPage.stateData';
import {
  isBookingProcess,
  resolveLatestProcessName,
  TX_TRANSITION_ACTOR_PROVIDER,
} from '../../transactions/transaction';
import { useConfiguration } from '../../context/configurationContext';

export const data = [
  ['Pizza', 'Popularity'],
  ['Pizza', 10],
  ['Pepperoni', 45],
  ['Hawaiian', 45],
];

export const databar = [
  ['Year', ''],
  ['2014', 200],
  ['2015', 250],
  ['2016', 300],
  ['2017', 350],
];

export const dataLines = [
  ['Year', 'Sales', 'Expenses'],
  ['2013', 1000, 400],
  ['2014', 1170, 460],
  ['2015', 660, 1120],
  ['2016', 1030, 540],
];

const BrandingIconTypes = {
  DEALS: 'deals',
  SOLD: 'sold',
  TRENDUP: 'trentup',
  TOTAL: 'total',
};

const badgeIcon = {
  BUCKET: 'bucket',
  BUZZ: 'buzz',
  SCANQR: 'scanqr',
  ACCEPTCODE: 'acceptcode',
};

const badgeCard = [
  {
    icon: badgeIcon.BUCKET,
    heading: 'Create Deal',
  },
  {
    icon: badgeIcon.BUZZ,
    heading: 'Send A Buzz',
  },
  {
    icon: badgeIcon.SCANQR,
    heading: 'Scan QR',
  },
  {
    icon: badgeIcon.ACCEPTCODE,
    heading: 'Accept Code',
  },
];

const DashboardPageComponent = props => {
  const { currentUser } = useSelector(state => state?.user);
  const { transactionRefs, pagination, fetchInProgress, fetchOrdersOrSalesError } =
    useSelector(state => state.LandingPage) || {};
  // const{listing} = useSelector((state)=> state.listing)
  const appState = useSelector(state => state);
  const transactions = getMarketplaceEntities(appState, transactionRefs);
  const config = useConfiguration();
  const date = new Date();
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const filteredTransactions = transactions.filter(transaction => {
    const lastTransitionDate = transaction?.attributes?.lastTransitionedAt;
    const tt = lastTransitionDate.toLocaleDateString('en-US', options);
    return tt === formattedDate;
  });
  const totalDeals = transactions.filter(
    tx => tx.listing.attributes.publicData.listingType == 'deal'
  );

  const { intl, scrollingDisabled } = props;
  const pickType = lt => conf => conf.listingType === lt;
  const findListingTypeConfig = publicData => {
    const listingTypeConfigs = config.listing?.listingTypes;
    const { listingType } = publicData || {};
    const foundConfig = listingTypeConfigs?.find(pickType(listingType));
    return foundConfig;
  };
  const toTxItem = tx => {
    const transactionRole = TX_TRANSITION_ACTOR_PROVIDER;
    let stateData = null;
    try {
      stateData = getStateData({ transaction: tx, transactionRole, intl });
    } catch (error) {
      // If stateData is missing, omit the transaction from InboxItem list.
    }

    const publicData = tx?.listing?.attributes?.publicData || {};
    const foundListingTypeConfig = findListingTypeConfig(publicData);
    const { transactionType, stockType } = foundListingTypeConfig || {};
    const process = tx?.attributes?.processName || transactionType?.transactionType;
    const transactionProcess = resolveLatestProcessName(process);
    const isBooking = isBookingProcess(transactionProcess);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          transactionRole={transactionRole}
          tx={tx}
          intl={intl}
          stateData={stateData}
          stockType={stockType}
          isBooking={isBooking}
        />
      </li>
    ) : null;
  };

  return (
    <Page title={''} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.mainLayout}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
      >
        <div className={css.dashboardWrapper}>
          <div className={css.dashboardHeader}>
            <div className={css.dashboardHeading}>
              Hello {currentUser?.attributes?.profile?.firstName},
            </div>
            <div className={css.dashboardSubHeading}>
              Here’s a summary of your business performance
            </div>
          </div>
          <div className={css.dashboardBody}>
            <div className={css.dealsCardsWrapper}>
              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{totalDeals.length}</div>
                  <div className={css.heading}>{'Active Deals'}</div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.DEALS} />
                </div>
              </div>

              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{filteredTransactions?.length}</div>
                  <div className={css.heading}>{'Sold Today'}</div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.SOLD} />
                </div>
              </div>

              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{totalDeals.length}</div>
                  <div className={css.heading}>{'Total Deals'}</div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.TRENDUP} />
                </div>
              </div>
              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{transactions?.length}</div>
                  <div className={css.heading}>Total Sales</div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.TOTAL} />
                </div>
              </div>
            </div>
            <div className={css.badgeCardBox}>
              {badgeCard.map((item, i) => {
                return (
                  <div className={css.badgeCard}>
                    <div>
                      <BrandingIconCard type={item.icon} />
                    </div>
                    <div className={css.heading}>{item.heading}</div>
                  </div>
                );
              })}
            </div>
            <div className={css.cartList}>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Fulfilment Ratio</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.subHeading}>This Week</div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="PieChart"
                    data={data}
                    // options={options}
                    width={'100%'}
                    height={'100%'}
                  />
                </div>
              </div>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Sales Volume</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="100%"
                    data={databar}
                    // options={options}
                  />
                </div>
              </div>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Total Revenue</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="100%"
                    data={data}
                    // options={options}
                  />
                </div>
              </div>
            </div>
            <div className={css.bookingOrder}>
              <div className={css.bookingHeader}>
                <div className={css.bookingHeading}>Latest Bookings & Orders</div>
                <div className={css.moreLink}>
                  <a href="#">
                    More
                    <BrandingIconCard type="rightarrow" />
                  </a>
                </div>
              </div>
              <div className={css.bookingTable}>
                {fetchOrdersOrSalesError ? (
                  <p className={css.error}>
                    <FormattedMessage id="InboxPage.fetchFailed" />
                  </p>
                ) : null}
                <ul className={css.itemList}>
                  {!fetchInProgress ? (
                    transactions.map(toTxItem)
                  ) : (
                    <li className={css.listItemsLoading}>
                      <IconSpinner />
                    </li>
                  )}
                </ul>
                {pagination && pagination.totalPages > 1 ? (
                  <PaginationLinks
                    className={css.pagination}
                    pageName="DashboardPage"
                    pagePathParams={'/'}
                    pagination={pagination}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

DashboardPageComponent.defaultProps = {};

DashboardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const DashboardPage = compose(injectIntl)(DashboardPageComponent);

export default DashboardPage;
