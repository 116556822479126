import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import categoryImage from '../../../../assets/images/categories.png';
import { convertToTitleCase, transformCategories } from '../../../../util/data';
import { categoriesListing } from '../../LandingPage.duck';
import styles from './TopCategories.module.css';

/**
 * TopCategories component fetches and displays the top categories.
 * It transforms the flat category data into a hierarchical structure.
 */
const TopCategories = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  // Fetch category data when the component mounts
  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const data = await dispatch(categoriesListing());
        setCategories(data); // Store fetched categories in state
      } catch (error) {
        console.error('Failed to fetch top categories:', error);
      }
    };

    fetchCategoriesData(); // Call the fetch function
  }, [dispatch]);

  // Transform the fetched categories into a hierarchical structure
  const organizedCategories = transformCategories(categories);

  return (
    <div className={styles.topCategoriesWrapper}>
      <div className={styles.topCategoriesHeading}>Top Categories</div>
      <div className={styles.categoriesCard}>
        {Array.isArray(organizedCategories) && organizedCategories.length > 0 ? (
          organizedCategories.map(categoryItem =>
            categoryItem.childCategory.map(childCategory => (
              <div key={childCategory.id} className={styles.categoriesList}>
                <div className={styles.categoriesImage}>
                  <img src={categoryImage} alt={`Category: ${childCategory.label}`} />
                </div>
                <div className={styles.categoriesTitle}>
                  {convertToTitleCase(childCategory.label)}
                </div>
              </div>
            ))
          )
        ) : (
          // Display message if no categories are available
          <div className={styles.noCategories}>No categories available.</div>
        )}
      </div>
    </div>
  );
};

// Default props for the component
TopCategories.defaultProps = {
  className: null,
  rootClassName: null,
};

// Prop types for type checking
TopCategories.propTypes = {
  className: string,
  rootClassName: string,
};

export default TopCategories;
