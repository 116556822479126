import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './BestDeals.module.css';
import dealsImage from '../../../../assets/images/dealsimage.png';
import dealsIcon from '../../../../assets/images/dealsicon.png';
import { ResponsiveImage } from '../../../../components';

const BestDeals = () => {
    return (
        <div className={css.bestDealsWrapper}>
            <div className={css.dealsLeft}>
                <ResponsiveImage
                    alt="Deals Image"
                    image={dealsImage}
                    variants={"custom-image"}
                    className={css.dealsImage}
                />

                <ResponsiveImage
                    alt="Deals Icon"
                    image={dealsIcon}
                    variants={"custom-image"}
                    className={css.dealsIcon}
                />
            </div>
            <div className={css.dealsRight}>
                <h2>Send a Buzz of your
                    best deals to the
                    perfect customers</h2>
                <p>
                    Utilize our promotional tools to market your deals effectively.
                    Access analytics to understand customer behavior and improve your marketing strategies.
                </p>
            </div>
        </div>
    )
};

BestDeals.defaultProps = {
    className: null,
    rootClassName: null,
};

BestDeals.propTypes = {
    className: string,
    rootClassName: string,
};

export default BestDeals;
