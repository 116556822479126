import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './MerchantHero.module.css';
import { Button } from '../../../../components';

const MerchantHero = () => {
    return (
        <div className={css.merchantWrapper}>
            <h1 className={css.merchantHeading}>
                Manage bookings & grow your business with deals
            </h1>
            <p className={css.merchantSubHeading}>
                NOW! is a simple business management software
                that helps service based businesses manage their bookings & grow their business using deals and promotions.
            </p>
            <div className={css.bottomButton}>
                <Button className={css.startFreeButton}>
                    Get started for free
                </Button>
            </div>
        </div>
    )
};

MerchantHero.defaultProps = {
    className: null,
    rootClassName: null,
};

MerchantHero.propTypes = {
    className: string,
    rootClassName: string,
};

export default MerchantHero;
